export const getInterestsQuery = (userId) => `{
  interests(limit: 40, page: 1, user_id: ${userId}) {
    data {
      id,
      gender,
      title,
      user {
        id
      },
      luxity_styles {
        id,
        name,
      },
      luxity_brands {
        id,
        name
      },
      min_price,
      max_price,
      min_shoe_size {
        id,
        size,
        type
      },
      max_shoe_size {
        id,
        size,
        type
      },
      category_type
    },
    total,
    per_page,
    current_page,
    from,
    to,
    last_page,
    has_more_pages
  }

  luxity_product_interests(limit: 40, page: 1, user_id: ${userId}) {
    data {
      id,
      user {
        id
      },
      luxity_material {
        id,
        name,
      },
      luxity_model {
        id,
        name,
      },
      luxity_brand {
        id,
        name
      },

    },
    total,
    per_page,
    current_page,
    from,
    to,
    last_page,
    has_more_pages
  }


}`;

export const getShoeSizeQuery = () => `{
  luxity_eu_shoe_sizes {
    id,
    size
  },
  luxity_uk_shoe_sizes {
    id,
    size
  }
}`;

export const getBrandsQuery = (
  limit,
  page,
  sort_by,
  category_type,
  nameFilter,
) => {
  const categoryFilter =
    category_type !== undefined ? `category_type: ${category_type}` : '';
  const nameFilterCondition =
    nameFilter !== undefined
      ? `filter: { name: { like: "${nameFilter}" } }`
      : '';

  return `{
    luxity_brands (limit: ${limit}, page: ${page}, ${categoryFilter} ${nameFilterCondition}){
      data {
        id,
        name,
      },
      total,
      per_page,
      current_page,
      from,
      to,
      last_page,
      has_more_pages
    }
  }`;
};

export const getModelsQuery = (brand_id) => {
  return `{
    luxity_models(limit: 1000, page: 1, sort_by: "name|asc", brand_id: ${brand_id}) {
      data {
        id
        name
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }`;
};

export const getMaterialsQuery = (brand_id) => {
  return `{
    luxity_materials(limit: 1000, page: 1, sort_by: "name|asc", brand_id: ${brand_id}) {
      data {
        id
        name
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }`;
};

export const getStylesQuery = (
  limit,
  page,
  sort_by,
  category_type,
  nameFilter,
) => `{
    luxity_styles (limit: ${limit}, page: ${page}, category_type: ${category_type}, sort_by:"total_products|desc", get_product_totals:true, filter: {style: {like: "${nameFilter}" } } ){
      data {
        id,
        name,
      },
      total,
      per_page,
      current_page,
      from,
      to,
      last_page,
      has_more_pages
    }
  }`;

export const getNotificationOptionsQuery = (userId) => `{
    user (id: ${userId}) {
      email_interest_send_schedule
      whatsapp_interest_send_schedule
      vip
    },
  __type(name:"interest_schedule_enum") {
    enumValues {
      description
      name
    }
  }
}`;

export const getConditionsQuery = () => {
  return `{
    conditions_list(limit: 10, page: 1) {
      data {
        id
        name
      }
      total
      per_page
      current_page
      last_page
    }
  }`;
};
