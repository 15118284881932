import React from 'react';
import api from 'constants/api';
import axios from 'helpers/axios';
import { Interests } from '@bonlineza/luxity-lib';
import {
  getBrandsQuery,
  getInterestsQuery,
  getShoeSizeQuery,
  getStylesQuery,
  getMaterialsQuery,
  getModelsQuery,
  getNotificationOptionsQuery,
  getConditionsQuery,
} from 'views/Interests/query';
import { connect } from 'react-redux';

const InterestsPage = ({ userId }) => {
  const setSchedule = (selectedOption, notificationType) => {
    const payload = {
      interest_send_schedule: selectedOption,
      notification_type: notificationType,
    };
    return axios.post(api.interests.setInterestSchedule(), payload);
  };

  const getSchedule = () => {
    const url = `${api.graphql()}?gql_id=getSchedule`;
    return axios.post(url, {
      query: getNotificationOptionsQuery(userId),
    });
  };

  const interestQuery = getInterestsQuery(userId);

  return (
    <div className="interest">
      <Interests
        addInterestApi={api.interests.createInterest()}
        addLuxityProductInterestApi={api.interests.createLuxityProductInterest()}
        baseGraphApi={api.graphql()}
        deleteInterestApi={api.interests.deleteInterest}
        deleteLuxityProductInterestApi={
          api.interests.deleteLuxityProductInterest
        }
        requestInstance={axios}
        interestQuery={interestQuery}
        getBrandsGraphQuery={getBrandsQuery}
        getStylesGraphQuery={getStylesQuery}
        getShoesGraphQuery={getShoeSizeQuery}
        setSchedule={setSchedule}
        getSchedule={getSchedule}
        getMaterialsGraphQuery={getMaterialsQuery}
        getModelsGraphQuery={getModelsQuery}
        getConditionsGraphQuery={getConditionsQuery}
      />
    </div>
  );
};

const mapState = ({ authStore }) => ({
  userId: authStore.user.id,
});

export default connect(mapState)(InterestsPage);
