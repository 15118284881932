/** @format */
import config from 'config/config';

export const method = {
  POST: 'post',
  GET: 'get',
};

export default {
  getWPMenuHTML: () => config.LUXITY_SHOP_URL,
  graphql: () => `${config.API_URL}/graphql`,
  interests: {
    deleteInterest: (id) => `${config.API_URL}/api/interests/${id}`,
    deleteLuxityProductInterest: (id) =>
      `${config.API_URL}/api/interests/luxity-product/${id}`,
    setInterestSchedule: () => `${config.API_URL}/api/user/interest-schedule`,
    createInterest: () => `${config.API_URL}/api/interests`,
    createLuxityProductInterest: () =>
      `${config.API_URL}/api/interests/luxity-product`,
  },
  clientReview: {
    getId: (token) => `${config.API_URL}/api/client-reviews/${token}`,
    submit: (client_review_id, token) =>
      `${config.API_URL}/api/client-reviews/${client_review_id}/${token}`,
  },
  user: {
    // POSTS
    LOGIN: `${config.API_URL}/api/login`,
    UPDATE_PROFILE: `${config.API_URL}/api/profile/update`,
    UPDATE_PASSWORD: `${config.API_URL}/api/password/reset`,
    SET_PASSWORD: `${config.API_URL}/api/password`,
    REGISTER_PROFILE: `${config.API_URL}/api/profile/register`,
    REQUEST_PASSWORD_RESET: `${config.API_URL}/api/password/request_reset`,
    VALIDATE_PASSWORD_RESET_TOKEN: `${config.API_URL}/api/password/reset/validate`,
    REQUEST_OTP: `${config.API_URL}/api/password/forgot`,
    FORGOT_PASSWORD_VERIFY: `${config.API_URL}/api/password/verification`,
    VOID_SALE: `${config.API_URL}/api/order_sale`,
    UNSUBSCRIBE_INTERESTS: `${config.API_URL}/api/user/email-interest-schedule`,
    CANCEL_ORDER: `${config.API_URL}/api/order_sale`,
    // GETS
    GET_PROFILE: `${config.API_URL}/api/profile`,
    BANK_DETAILS: `${config.API_URL}/api/account`,
    UPDATE_CARRIER_TYPE: `${config.API_URL}/api/order_sale`,
    // LOCATION/REDIRECT
    LOGOUT: config.LUXITY_SHOP_URL,
    // COMBINED OPERATIONS
    ADDRESS: `${config.API_URL}/api/addresses`,
    COUPON: `${config.API_URL}/api/coupon/add`,
    INVITE_A_FRIEND: `${config.API_URL}/api/coupon/invite-a-friend`,
    COMMUNICATION_PREFERENCE_UPDATE: `${config.API_URL}/api/communication-preference`,
    COMMUNICATION_PREFERENCE: `${config.API_URL}/api/communication-preference`,
  },
  data: {
    COUNTRY_CODES: `${config.API_URL}/api/country_codes`,
    DASHBOARD: `${config.API_URL}/api/dashboard`,
    GET_PAYMENT_INFO: `${config.API_URL}/api/orders/payment`,
    CURRENT_ORDERS: `${config.API_URL}/api/order_sale/current`,
    ORDER_HISTORY: `${config.API_URL}/api/order_sale/history`,
    CURRENT_SALES: `${config.API_URL}/api/sale_purchase/current`,
    SALES_HISTORY: `${config.API_URL}/api/sale_purchase/history`,
    PAY_OUTSTANDING_AMOUNT: `${config.API_URL}/api/pay`,
    PAY_WITH_CREDIT: `${config.API_URL}/api/pay-with-credit`,
    COUPONS: `${config.API_URL}/api/coupon`,
    UPDATE_LEAD_STATUS: `${config.API_URL}/api/luxity-lead/update-status`,
    UPDATE_FOLLOW_UP_STATUS: `${config.API_URL}/api/luxity-lead/update-follow-up-status`,
  },
};
